import React from 'react';
import { TextBoxFull, Wrapper, FlexContainer } from '../styles/globalstyles';
import SEO from '../components/seo';
import {css} from '@emotion/react';


const AGB = () => (
<Wrapper>
    <SEO title="AGB" />
    <FlexContainer direction="column" align="flex-start" justify="space-between" css={css`padding-top: 8em !important;`}>
        <h1>AGB - Allgemeine Geschäftsbedingungen </h1>

        <TextBoxFull>
            <h4>1. Geltungsbereich</h4>
            <p>Für die Beratungsleistungen gelten immer die im Anschluss formulierten Geschäftsbedingungen. Andere Bestimmungen gelten nicht. Änderungen und Ergänzungen bedürfen der Schriftform.</p>
        </TextBoxFull>
        <TextBoxFull>
            <h4>2. Leistungserbringung</h4>
            <p>Die Kunden verpflichten sich zu folgenden Leistungen:
            Ernennung eines Projektleiters, der für alle erforderlichen geschäftlichen Aktivitäten autorisiert ist.
            Erforderliche Informationsbereitstellung.
            Bereitstellung von Mitarbeitern, Räumlichkeiten, Beamer, Flipchart, Pinnwand, W-LAN, DV-und Telekommunikationseinrichtungen.
            Tritt der Kunde ohne berechtigten Grund von den vereinbarten Leistungen zurück oder nimmt er diese nicht an, sind wir berechtigt, das vereinbarte Honorar in voller Höhe in Rechnung zu stellen. Aufwendungen, die wir durch die Nichterbringung einsparen oder anderweitig einsetzen können, werden nicht berechnet.
            Vereinbarte Beratungstermine können bis 10 Tage vor Realisierung verschoben bzw. storniert werden. Kurzfristigere Absagen und Verschiebungen werden mit 50% des Honorarsatzes in Abrechnung gebracht.
            </p>
        </TextBoxFull>
        <TextBoxFull>
            <h4>3. Zahlungsbedingungen</h4>
            <p>Unsere Rechnungen sind zahlbar innerhalb 8 Tagen nach Erhalt rein netto. Die erste Rechnung ist vor Projektstart fällig.
            Alle hier aufgeführten Beträge und Honorare verstehen sich zzgl. der gesetzlichen MwSt. von derzeit 19%.
            Befindet sich der Kunde mit seiner Zahlung mehr als eine Woche im Verzug, sind wir berechtigt, das Projekt ohne weitere Ankündigung zu unterbrechen. Ein dadurch ggf. entstehender Schaden ist nicht durch uns zu vertreten. Dies gilt auch bei Zahlungsunfähigkeit (Insolvenz, etc.).</p>
        </TextBoxFull>
        <TextBoxFull>
            <h4>4. Schadensersatz/ Haftung</h4>
            <p>Der Auftragnehmer übernimmt keine Haftung für das Erreichen der Zielsetzung für das Projekt.
            Mit Ausnahme von Vorsatz oder grober Fahrlässigkeit sind Schadensansprüche des Kunden ausgeschlossen. Vertragliche Haftungsansprüche verjähren nach 6 Monaten.
            Die Verantwortung für unternehmerische Entscheidungen und Geschäftsführung verbleibt beim Auftraggeber.
            </p>
        </TextBoxFull>
        <TextBoxFull>
            <h4>5. Vertraulichkeit</h4>
            <p>Alle Unterlagen und Informationen von Auftraggeber und Auftragnehmer, die im Rahmen der vorgenannten Zusammenarbeit ausgetauscht oder zugänglich gemacht werden, dienen ausschließlich der Projektzielerreichung und können in diesem Rahmen genutzt und vervielfältigt werden. Eine Weitergabe an Dritte ist nur mit schriftlichem Einverständnis möglich.
            </p>
        </TextBoxFull>
        <TextBoxFull>
            <h4>6. Schlussbestimmungen</h4>
            <p>Sollten einzelne Bedingungen ungültig sein, so wird die rechtliche Gültigkeit des Vertragsverhältnisses nicht berührt. Die unwirksame Bestimmung wird in diesem Fall von beiden Seiten durch eine so weit wie möglich nahekommende Regelung ersetzt.
            </p>
        </TextBoxFull>
        <TextBoxFull>
            <h4>7. Gerichtsstandort</h4>
            <p>Es gilt das Recht der Bundesrepublik Deutschland und Gerichtsstand ist Freiburg.
            </p>
        </TextBoxFull>
        
    </FlexContainer>


</Wrapper>

)
export default AGB;